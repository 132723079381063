.page-header {
  height: 450px;
  min-height: 450px;
  .page-header-image {
    z-index: 0;
  }
  .page-header-title {
    font-weight: 800;
    margin-bottom: 10px;
  }
  .page-header-solgan {
    font-size: 20px;
  }
}
.home-header {
  min-height: 100vh;
  // max-height: 999px;
  padding: 0;
  color: #FFFFFF;
  position: relative;
  overflow: hidden;
  .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .page-header-title {
    font-weight: 800;
    margin-bottom: 10px;
  }
  .page-header-solgan {
    font-size: 20px;
  }
}
.home-wapper {
  height: 100%;
  z-index: 1;
  text-align: center;
  position: relative;
  margin-left: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .home-main {
    max-width: 660px;
    text-align: left;
    transform: scale(1.5);
    transform-origin: left;
    .home-slogan {
      font-size: 42px;
      font-weight: 700;
    }
    .home-desc {
      margin: 40px 0 60px;
      font-size: 20px;
    }
    
  }
  .home-action {
    max-width: 660px;
    text-align: left;
    transform: scale(1.5);
    transform-origin: left;
    button {
      font-weight: 700;
      border-radius: 8px;
      font-size: 16px;
      padding: 10px;
      &:nth-child(1) {
        margin-right: 20px;
      }
    }
  }
  .zm {
    color: rgb(87, 149, 163);
    background: #fff;
    width: 128px;
    height: 40px;
    &:hover {
      background: rgb(205,48,37);
      color: #fff;
    }
  }
  .lj {
    border-color: #fff;
    color: #fff;
    width: 128px;
    height: 40px;
    &:hover {
      border-color: rgb(205,48,37);
      background: rgb(205,48,37);
      color: #fff;
    }
  }
}
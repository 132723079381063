.search-list-wapper {
  min-height: 100vh;
  background: #eaeaea;
  position: relative;
  &>nav {
    position: absolute;
  }
  .search-list-main {
    background: rgb(255, 255, 255);
    filter: drop-shadow(rgba(0, 0, 0, 0.28) 0px 8px 16px);
    border-radius: 6.5px;
    transition: unset;
    margin: 0 30px;
    margin-top: -30px;
    padding: 1px 0;
    .search-list-lastest {
      margin-top: 50px;
      padding: 0 30px;
      .lastest-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 35px;
        .lastest-title {
          border-left: 8px solid #dd4134;
          font-weight: 800;
          font-size: 30px;
          line-height: 20px;
          padding-left: 10px;
        }
      }
      .search-list-search-bar {
        display: flex;
        border: 1px solid #e8e8e8;
        font-size: 20px;
        border-radius: 9px;
        overflow: hidden;
        margin-bottom: 50px;
        .search-bar-labels {
          padding: 0;
          display: flex;
          align-items: center;
          margin: 0;
          height: 42px;
          flex: 1;
          li {
            flex: 1;
            text-align: center;
            border-right: 1px solid #d8d8d8;
            height: 100%;
            line-height: 42px;
            cursor: pointer;
          }
          .active {
            background: #4B98A9;
            color: #fff;
          }
        }
        .search-bar {
          display: flex;
          width: 456px;
          align-items: center;
          flex-shrink: 0;
          input {
            border: none;
          }
          .search-btn {
            width: 60px;
            background: #4B98A9;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 9px;
            cursor: pointer;
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
      .lastest-list {
        padding: 0;
        li {
          display: flex;
          margin-bottom: 50px;
          cursor: pointer;
          .recommend-img {
            width: 290px;
            height: 195px;
            border-radius: 8px;
            overflow: hidden;
            margin-right: 50px;
            flex-shrink: 0;
            box-shadow: rgba(0,0,0,0.18) 0px 2px 4px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .recommend-main {
            display: flex;
            flex-direction: column;
            padding: 5px 0;
            .recommend-title {
              font-size: 24px;
              font-weight: 800;
            }
            .recommend-desc {
              font-size: 20px;
              flex: 1;
              margin-top: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
            .recommend-from-date {
              display: flex;
              justify-content: space-between;
              margin-top: 20px;
              .recommend-from {
                display: flex;
                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 8px;
                }
                .lastest-tag {
                  display: inline-block;
                  padding: 1px 6px;
                  width: 6em;
                  text-align: center;
                  background: #d6d6d6;
                  margin-right: 5px;
                  border-radius: 40px;
                }
              }
              .recommend-date {
                display: flex;
                .publish-status {
                  color: rgb(205, 48, 37);
                }
                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
      .lastest-list {
        .recommend-desc {
          -webkit-line-clamp: 2 !important;
        }
      }
    }
    .more {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .line-left, .line-right {
        flex: 1;
        height: 1px;
        background: #DD4B39;
      }
      button {
        width: 120px;
        margin: 30px;
      }
    }
    .subscribe-tips {
      h3 {
        font-size: 25px;
        font-weight: 800;
        margin-bottom: 8px;
      }
      p {
        font-size: 16px;
        margin-bottom: 0;
      }
    }
  }
  .btn-3d8d9f {
    background-color: #3d8d9f;
    border-radius: 20px;
    width: 160px;
    margin: 0;
    font-size: 16px;
  }
}
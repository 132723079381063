.partner-wapper {
  min-height: 100vh;
  background: #eaeaea;
  position: relative;
  &>nav {
    position: absolute;
  }
  .partner-main {
    background: rgb(255, 255, 255);
    filter: drop-shadow(rgba(0, 0, 0, 0.28) 0px 8px 16px);
    border-radius: 6.5px;
    transition: unset;
    margin: 0 30px;
    margin-top: -30px;
    padding: 1px 0;
    .partner-content {
      display: flex;
      flex-wrap: wrap;
      a {
        margin-top: 30px;
        &:hover {
          text-decoration:none !important;
        }
      }
      .partner-item {
        width: 300px;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span {
          color: #333;
          font-size: 16px;
          font-weight: 800;
        }
        &>div {
          height: 200px;
          line-height: 200px;
        }
        img {
          min-width: 150px;
          max-height: 200px;
        }
      }
    }
  }
  .btn-3d8d9f {
    background-color: #3d8d9f;
    border-radius: 20px;
    width: 160px;
    margin: 0;
    font-size: 16px;
  }
}
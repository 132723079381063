.contact-us-wapper {
  min-height: 100vh;
  background: #eaeaea;
  position: relative;
  &>nav {
    position: absolute;
  }
  .page-header {
    height: 310px !important;
    min-height: 310px !important;
  }
  .contact-us-main {
    background: rgb(255, 255, 255);
    filter: drop-shadow(rgba(0, 0, 0, 0.28) 0px 8px 16px);
    border-radius: 6.5px;
    transition: unset;
    margin: 0 30px;
    margin-top: -30px;
    .contact-us-content {
      padding: 60px 0 100px;
      .content-form {
        .content-form-title {
          h3 {
            margin-bottom: 10px;
            font-size: 34px;
            font-weight: 800;
            color: #333;
          }
          p {
            font-size: 18px;
          }
        }
        .content-form-body {
          font-size: 18px;
          .form-item {
            margin-bottom: 20px;
            input {
              border: none;
              border-bottom: 1px solid #e3e3e3;
              border-radius: 0;
              height: 40px;
              &:hover {
                border-color: rgb(65, 143, 215);
              }
              &:focus {
                border-color: rgb(65, 143, 215);
              }
            }
            textarea {
              border: 1px solid #e3e3e3;
              &:hover {
                border-color: rgb(65, 143, 215);
              }
              &:focus {
                border-color: rgb(65, 143, 215);
              }
            }
            .btn-3d8d9f {
              background-color: #3d8d9f;
              border-radius: 20px;
              width: 160px;
              font-size: 16px;
            }
          }
        }
      }
      .content-info-wapper {
        .row {
          height: 100%;
        }
        .content-info {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .content-info-item {
            // display: flex;
            display: none;
            .info-icon {
              width: 60px;
              height: 60px;
              // background: rgb(65,143,215);
              margin-right: 15px;
              flex-shrink: 0;
            }
            .info-detail {
              .info-detail-title {
                font-size: 18px;
                font-weight: 800;
                margin-bottom: 10px;
              }
              .info-detail-content {
                font-size: 16px;
                color: #666;
              }
            }
          }
        }
      }
    }
  }
}
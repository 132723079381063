.video-detail-wapper {
  min-height: 100vh;
  background: #eaeaea;
  position: relative;
  &>nav {
    position: absolute;
  }
  .video-detail-main {
    background: rgb(255, 255, 255);
    filter: drop-shadow(rgba(0, 0, 0, 0.28) 0px 8px 16px);
    border-radius: 6.5px;
    transition: unset;
    margin: 0 30px;
    margin-top: -30px;
    padding: 50px 0 80px;
    .video-detail-content {
      border-radius: 9px;
      background: #5C5C5C;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      .video-content {
        width: 780px;
        .video-wapper {
          width: 780px;
          height: 430px;
          video {
            width: 100%;
          }
          img {
            max-height: 100%;
            max-width: 100%;
          }
        }
        .video-desc {
          margin-top: 25px;
          color: #fff;
          .video-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 600;
            &>span:nth-child(1) {
              font-size: 24px;
              width: 615px;
              flex-shrink: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            &>span:nth-child(2) {
              font-size: 18px;
              text-align: right;
              width: 160px;
              flex-shrink: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .video-info {
            font-size: 18px;
            margin: 30px 0;
          }
          .video-detail-footer {
            display: flex;
            justify-content: space-between;
            .keyword {
              display: flex;
              align-items: center;
              .keyword-item {
                width: 86px;
                height: 24px;
                text-align: center;
                background: #30798A;
                color: #fff;
                border-radius: 24px;
                margin-left: 8px;
                font-size: 16px;
              }
            }
            .share {
              display: flex;
              align-items: center;
              .share-icon {
                width: 30px;
                height: 30px;
                border-radius: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: 100%;
                }
              }
              .share-list {
                display: flex;
                margin-left: 10px;
                align-items: center;
                .share-item {
                  width: 84px;
                  height: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 30px;
                  margin-left: 6px;
                  color: #fff;
                  font-weight: 600;
                  cursor: pointer;
                  img {
                    width: 19px;
                    height: 16px;
                    margin-right: 8px;
                  }
                  span {
      
                  }
                }
              }
            }
          }
        }
      }
      .video-list {
        width: 380px;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        .video-item {
          width: 380px;
          height: 205px;
          position: relative;
          // background: #11447c;
          border-radius: 9px;
          overflow: hidden;
          margin-top: 30px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
          .video-status {
            background: rgba(0, 0, 0, 0.32);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 35px;
            text-align: center;
            line-height: 35px;
            color: #fff;
          }
          .video-over {
            background: rgba(255, 255, 255, 0.32);
          }
        }
        &>div:nth-child(1) {
          margin: 0;
        }
      }
    }
    

  }
}
.login-header {
  height: 100vh !important;
  .page-header {
    &::before {
      background: none;
    }
  }
}
.login-content {
  .input-group, .form-group {
    background: rgba(255,255,255,0.6);
    border-radius: 14px;
    .form-control {
      color: #333 !important;
      &::placeholder {
        color: #333 !important;
      }
    }
  }
  .btn-round {
    border-radius: 14px !important;
    background: rgb(61, 111, 122);
    color: #fff;
    font-weight: 800;
    &:hover {
      background: rgb(233,58,0);
    }
  }
  .container {
    height: auto;
  }
  .now-ui-icons {
    color: #333;
  }
}
.login-page .card-login .logo-container {
  width: 150px;
  margin-bottom: 30px;
}
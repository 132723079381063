.education-wapper {
  min-height: 100vh;
  background: #eaeaea;
  position: relative;
  &>nav {
    position: absolute;
  }
  .education-main {
    background: rgb(255, 255, 255);
    filter: drop-shadow(rgba(0, 0, 0, 0.28) 0px 8px 16px);
    border-radius: 6.5px;
    transition: unset;
    margin: 0 30px;
    margin-top: -30px;
    padding: 1px 0;
    .swiper-wapper {
      margin: 30px 0;
      justify-content: space-between;
      .forum {
        background: #f1f1f1;
        height: 450px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .forum-header {
          background: #ca2924;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #fff;
          padding: 10px 30px;
          .header-title {
            font-size: 18px;
            font-weight: 800;
            display: flex;
            align-items: center;
            img {
              margin-right: 4px;
            }
          }
        }
        .forum-body {
          padding: 20px 30px 30px;
          display: flex;
          flex-direction: column;
          flex: 1;
          .forum-body-title {
            font-size: 22px;
            font-weight: 800;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .forum-body-content {
            font-size: 20px;
            flex: 1;
            margin: 20px auto;
            margin-bottom: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
          }
          .forum-body-footer {
            display: flex;
            justify-content: space-between;
            span {
              display: inline-block;
              background: #d3d3d3;
              border-radius: 20px;
              padding: 1px 10px;
              margin-right: 5px;
            }
          }
        }
      }
      .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        .swiper-pagination-bullet {
          background: #fff;
          opacity: 1;
        }
        .swiper-pagination-bullet-active {
          width: 12px;
          height: 12px;
          background: #fff;
        }
      }
      .swiper-item {
        height: 450px;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
        img {
          width: 800px;
          height: 450px;
        }
        .tips {
          position: absolute;
          width: 50px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #bf272a;
          font-size: 14px;
          font-weight: 600;
          color: #fff;
          right: 0;
          top: 0;
          border-bottom-left-radius: 5px;
        }
      }
    }
    .education-lastest {
      margin-top: 50px;
      // padding: 0 30px;
      .lastest-title {
        border-left: 8px solid #dd4134;
        font-weight: 800;
        font-size: 30px;
        line-height: 20px;
        padding-left: 10px;
        margin-bottom: 35px;
      }
      .lastest-content {
        display: flex;
        flex-wrap: wrap;
        &>div {
          position: relative;
          width: 380px; 
          height: 345px;
          cursor: pointer;  
        }
      }
      .lastest-img {
        width: 320px;
        height: 180px;
        position: relative;
        margin: 0 auto;
        z-index: 99;
        .tips {
          position: absolute;
          width: 50px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #bf272a;
          font-size: 14px;
          font-weight: 600;
          color: #fff;
          right: 0;
          top: 0;
          border-bottom-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        img {
          width: 100%;
          height: 180px;
          background: #ccc;
          border-radius: 8px;
        }
      }
      .lastest-item {
        margin-top: 40px;
        height: calc(100% - 40px);
        background: rgb(255, 255, 255);
        filter: drop-shadow(rgba(0, 0, 0, 0.18) 0px 2px 4px);
        transition: unset;
        padding: 160px 15px 15px;
        display: flex;
        flex-direction: column;
        .publish-name {
          display: flex;
          justify-content: space-between;
          font-size: 18px;
          font-weight: 800;
          .publish-time {
            font-size: 14px;
            color: #c4c4c4;
            font-weight: 400;
          }
        }
        .publish-title {
          flex: 1;
          font-size: 20px;
          // font-weight: 800;
          margin-top: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .publish-tags {
          display: flex;
          margin-top: 15px;
          span {
            background: #d8d6d6;
            margin-right: 8px;
            border-radius: 10px;
            width: 6em;
            text-align: center;
          }
        }
      }
      .lastest-pagination {
        text-align: center;
        margin-bottom: 30px;
      }
    }
    .more {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .line-left, .line-right {
        flex: 1;
        height: 1px;
        background: #DD4B39;
      }
      button {
        width: 120px;
        margin: 30px;
      }
    }
    .classic-case {
      height: 250px;
      display: flex;
      position: relative;
      margin-top: 30px;
      .case-bg {
        position: absolute;
        background: url('../../../assets/img/edu/case-bg.jpeg');
        background-size: cover;
        background-position: 50%;
        border-radius: 15px;
        height: 250px;
        justify-content: space-between;
        transition: unset;
        filter: blur(4.1px);
        inset: 0px;
        z-index: -1;
      }
      .case-list {
        min-width: 1200px;
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &>div:nth-child(1) {
          font-size: 42px;
        }
        .case-item {
          font-size: 28px;
          color: #fff;
          font-weight: 800;
          width: 115px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          img {
            width: 60px;
            height: 60px;
          }
        }
      }
    }
    .subscribe-tips {
      h3 {
        font-size: 25px;
        font-weight: 800;
        margin-bottom: 8px;
      }
      p {
        font-size: 16px;
        margin-bottom: 0;
      }
    }
    .subscribe-form {
      display: flex;
      align-items: flex-end;
      height: 100%;
      font-size: 16px;
      input {
        border: none;
        border-bottom: 1px solid #e3e3e3;
        border-radius: 0;
        height: 40px;
        width: 273px;
        margin: 0 10px;
        &:hover {
          border-color: rgb(65, 143, 215);
        }
        &:focus {
          border-color: rgb(65, 143, 215);
        }
      }
      textarea {
        border: 1px solid #e3e3e3;
        &:hover {
          border-color: rgb(65, 143, 215);
        }
        &:focus {
          border-color: rgb(65, 143, 215);
        }
      }
    }
  }
  .btn-3d8d9f {
    background-color: #3d8d9f;
    border-radius: 20px;
    width: 160px;
    margin: 0;
    font-size: 16px;
  }
}
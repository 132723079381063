.fixed-top {
  position: absolute;
}
.navbar-wapper {
  min-width: 1200px;
  .header-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 60px;
    position: relative;
    &>img {
      width: 60px;
      position: absolute;
      right: 0;
      // top: 5px;
    }
  }
  .navbar-logo {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
    &>img {
      width: 60px;
      margin-top: 6px;
    }
    span {
      font-size: 36px;
      margin-left: 10px;
      img {
        width: 200px;
      }
    }
  }
  .menu-collapse {
    position: relative;
    .menu-logout {
      position: absolute;
      bottom: -30px;
      right: 85px;
      padding-top: 18px;
      .logout-btn {
        font-size: 18px;
        background: rgba(255,255,255,0.3);
        padding: 5px 10px;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }
  .navbar-menu-item {
    height: 42px;
    line-height: 42px;
    margin-right: 45px;
    font-size: 24px;
    cursor: pointer;
    font-weight: 800;
  }
  .nav-item {
    text-align: center;
  }
  .toggler-menu {
    width: 37px;
    height: 27px;
    outline: 0;
    cursor: pointer;
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    .navbar-toggler-bar {
      display: block;
      position: relative;
      width: 22px;
      height: 1px;
      border-radius: 1px;
      background: #FFFFFF;
    }
    .top-bar {
      transition: all 0.2s;
      transform: rotate(0);
    }
    .middle-bar {
      width: 17px;
      transition: width .2s linear;
      margin: 7px 0;
      
    }
    &:hover .middle-bar{
      width: 22px;
    }
  }
  .navbar-menu-wapper {
    position: relative;
    width: 100%;
    height: 42px;
    overflow: hidden;
    .navbar-menu-list {
      position: absolute;
      top: 0;
      transition: all 0.3s;
      display: flex;
      justify-content: flex-end;
    }
    .show {
      right: 0;
    }
    .hide {
      right: -100%;
    }
  }
}
@font-face {
  font-family: 'GenSenMaruGothic TW TTF Regular';  //重命名字体名
  src: url('../../../assets/fonts/yuanquanheiti.ttf');  //引入字体
  font-weight: normal;
  font-style: normal;
}
.platform-header {
  min-height: 100vh;
  max-height: 999px;
  padding: 0;
  color: #FFFFFF;
  position: relative;
  overflow: hidden;
  &::before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
    // background-color: rgba(0, 0, 0, 0.5);
  }
  .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .page-header-title {
    font-weight: 800;
    margin-bottom: 10px;
    font-family: GenSenMaruGothic TW TTF Regular;
  }
  .page-header-solgan {
    font-size: 20px;
  }
  .content {
    text-align: center;
    margin-bottom: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 220px;
  }
}
.platform-card {
  background: transparent;
  box-shadow: none;
  transform: scale(1.2);
  .platform-title {
    color: #fff;
    text-align: right;
    padding-right: 30px;
    p {
      font-weight: 800;
      font-size: 20px;
    }
    h3 {
      font-weight: 800;
      font-size: 32px;
      margin-bottom: 12px;
    }
  }
  .card-body {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .platform-list {
    li {
      display: flex;
      justify-content: space-between;
      border-radius: 10px;
      background: #fff;
      color: #000;
      font-size: 22px;
      padding: 5px 8px;
      cursor: pointer;
      opacity: 0.6;
      margin-bottom: 20px;
      font-weight: 700;
      &:hover {
        opacity: 1;
      }
      span {
        line-height: 40px;
      }
    }
  }
  .rc-rate-star {
    margin-right: 0;
  }
  .text-center {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 30px;
    font-size: 16px;
    &>div {
      display: flex;
      flex-direction: column;
      text-align: right;
      &>div {
        span {
          font-weight: 800;
          font-size: 24px;
          margin-right: 4px;
        }
      }
    }
  }
}

.platform-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 30px;
  font-size: 16px;
  margin-top: 30px;
  margin-right: 50px;
  .info-name {
    width: 340px;
    text-align: left;
  }
  &>div {
    display: flex;
    flex-direction: column;
    text-align: right;
    &>div {
      span {
        font-weight: 800;
        font-size: 24px;
        margin-right: 4px;
      }
    }
  }
}

li {
  list-style: none;
}

.activity-detail-wapper {
  min-height: 100vh;
  background: #eaeaea;
  position: relative;
  &>nav {
    position: absolute;
  }
  .activity-detail-main {
    background: rgb(255, 255, 255);
    filter: drop-shadow(rgba(0, 0, 0, 0.28) 0px 8px 16px);
    border-radius: 6.5px;
    transition: unset;
    margin: 0 30px;
    margin-top: -30px;
    padding: 100px 0;
    .activity-detail-title {
      .title {
        font-size: 28px;
        font-weight: 800;
      }
      .publish-time {
        font-size: 20px;
        color: #777;
        text-align: right;
        margin: 50px;
      }
    }
    .activity-detail-content {

    }
    .activity-detail-footer {
      display: flex;
      justify-content: space-between;
      .keyword {
        display: flex;
        font-size: 19px;
        align-items: center;
        .keyword-item {
          width: 86px;
          height: 24px;
          text-align: center;
          background: #30798A;
          color: #fff;
          border-radius: 24px;
          margin-left: 8px;
          font-size: 16px;
        }
      }
      .share {
        display: flex;
        align-items: center;
        .share-icon {
          width: 42px;
          height: 42px;
          border-radius: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          // background: #0083FF;
        }
        .share-list {
          display: flex;
          margin-left: 10px;
          align-items: center;
          .share-item {
            width: 120px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 42px;
            margin-left: 10px;
            color: #fff;
            font-weight: 600;
            cursor: pointer;
            img {
              width: 19px;
              height: 16px;
              margin-right: 8px;
            }
            span {

            }
          }
        }
      }
    }

  }
}
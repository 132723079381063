.article-detail-wapper {
  min-height: 100vh;
  background: #eaeaea;
  position: relative;
  &>nav {
    position: absolute;
  }
  .article-detail-main {
    background: rgb(255, 255, 255);
    filter: drop-shadow(rgba(0, 0, 0, 0.28) 0px 8px 16px);
    border-radius: 6.5px;
    transition: unset;
    margin: 0 30px;
    margin-top: -30px;
    padding: 100px 0;
    .article-detail-title {
      .title {
        font-size: 28px;
        font-weight: 800;
      }
      .publish-time {
        font-size: 20px;
        color: #777;
        text-align: right;
        margin: 50px;
      }
    }
    .article-detail-content {

    }
    .article-detail-info {
      margin: 20px 0;
      font-size: 20px;
      display: flex;
      justify-content: space-between;
    }
    .article-detail-footer {
      display: flex;
      justify-content: space-between;
      .keyword {
        display: flex;
        font-size: 19px;
        align-items: center;
        .keyword-item {
          width: 86px;
          height: 24px;
          text-align: center;
          background: #30798A;
          color: #fff;
          border-radius: 24px;
          margin-left: 8px;
          font-size: 16px;
        }
      }
      .share {
        display: flex;
        align-items: center;
        .share-icon {
          width: 42px;
          height: 42px;
          border-radius: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
          }
        }
        .share-list {
          display: flex;
          margin-left: 10px;
          align-items: center;
          .share-item {
            width: 120px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 42px;
            margin-left: 10px;
            color: #fff;
            font-weight: 600;
            cursor: pointer;
            img {
              width: 19px;
              height: 16px;
              margin-right: 8px;
            }
            span {

            }
          }
        }
      }
    }

  }
  .lastest-title {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    color: #3C4858;
    font-weight: 800;
    margin-top: 60px;
    margin-bottom: 15px;
  }
  .lastest-img {
    position: absolute;
    top: 0;
    width: 320px;
    z-index: 99;
    left: 45px;
    .tips {
      position: absolute;
      width: 50px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background: #bf272a;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      right: 15px;
      top: 0;
      border-bottom-left-radius: 5px;
    }
    img {
      width: 100%;
      height: 180px;
      background: #ccc;
      border-radius: 8px;
    }
  }
  .lastest-item {
    margin-top: 40px;
    height: calc(100% - 40px);
    background: rgb(255, 255, 255);
    filter: drop-shadow(rgba(0, 0, 0, 0.18) 0px 2px 4px);
    transition: unset;
    padding: 160px 15px 15px;
    display: flex;
    flex-direction: column;
    .publish-name {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 800;
      .publish-time {
        font-size: 14px;
        color: #c4c4c4;
        font-weight: 400;
      }
    }
    .publish-title {
      flex: 1;
      font-size: 20px;
      font-weight: 800;
      margin-top: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    .publish-tags {
      display: flex;
      margin-top: 35px;
      span {
        background: #d8d6d6;
        margin-right: 8px;
        border-radius: 10px;
        width: 6em;
        text-align: center;
      }
    }
  }
}
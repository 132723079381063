.dynamic-wapper {
  min-height: 100vh;
  background: #eaeaea;
  position: relative;
  .page-header-image {
    background-position: center 928px;
  }
  &>nav {
    position: absolute;
  }
  .dynamic-main {
    background: rgb(255, 255, 255);
    filter: drop-shadow(rgba(0, 0, 0, 0.28) 0px 8px 16px);
    border-radius: 6.5px;
    transition: unset;
    margin: 0 30px;
    margin-top: -30px;
    padding: 1px 0;
    .swiper-wapper {
      margin: 30px 0;
      .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        .swiper-pagination-bullet {
          background: #fff;
          opacity: 1;
        }
        .swiper-pagination-bullet-active {
          width: 12px;
          height: 12px;
          background: #fff;
        }
      }
      .swiper-item {
        height: 500px;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
        img {
          min-width: 100%;
          min-height: 100%;
        }
        .tips {
          position: absolute;
          width: 90px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #bf272a;
          font-size: 14px;
          font-weight: 600;
          color: #fff;
          left: 0;
          top: 0;
          border-bottom-right-radius: 5px;
        }
      }
    }
    .dynamic-content-title {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 30px;
      &:hover {
        .title-text {
          .line {
            width: 100%;
          }
        }
      }
      .title-text {
        flex: 1;
        position: relative;
        span {
          color: rgb(27, 72, 110);
          font-weight: 800;
          &:nth-child(1) {
            font-size: 30px;
            margin-right: 10px;
          }
          &:nth-child(2) {
            font-size: 20px;
          }
        }
        .line {
          position: absolute;
          bottom: 0;
          height: 4px;
          left: 0;
          width: 7.5em;
          background: #dd4134;
          transition: all 0.3s;
        }
      }
      .title-next {
        flex: 1;
        color: rgb(27, 72, 110);
        font-weight: 800;
        font-size: 20px;
      }
    }
    .dynamic-content-list {
      padding: 0;
      .dynamic-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .dynamic-item-desc {
          display: flex;
          align-items: center;
          flex: 1;
          overflow: hidden;
          margin-right: 10px;
          .spot {
            width: 8px;
            height: 8px;
            border-radius: 6px;
            background: #949494;
            display: block;
            margin-right: 10px;
          }
          .label {
            display: block;
            padding: 2px 10px;
            background: #1b486e;
            color: #fff;
            font-weight: 800;
            margin-right: 10px;
            font-size: 18px;
          }
          p {
            margin: 0;
            font-size: 18px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            flex: 1;
          }
        }
        .dynamic-item-time {

        }
      }
    }
  }
}
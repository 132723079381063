.about-wapper {
  min-height: 100vh;
  background: #eaeaea;
  position: relative;
  &>nav {
    position: absolute;
  }
  .about-main {
    background: rgb(255, 255, 255);
    filter: drop-shadow(rgba(0, 0, 0, 0.28) 0px 8px 16px);
    border-radius: 6.5px;
    transition: unset;
    margin: 0 30px;
    margin-top: -30px;
    padding: 100px 0;
    .about-desc {
      margin-bottom: 50px;
      p {
        font-size: 20px;
        text-indent: 2em;
      }
    }
    .member {
      margin-bottom: 70px;
    }
    .researcher {
      &>p {
        font-size: 17px;
        color: #999;
        display: block;
        width: 820px;
        margin: 0 auto;
        overflow: hidden;
        height: 3em;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .researcher-desc {
        margin-top: 80px;
        .desc-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 15px 30px;
          &>span:nth-child(2) {
            color: #3C4858;
            font-size: 20px;
            margin: 20px 0;
          }
          &>span:nth-child(3) {
            font-size: 18px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          img {
            width: 180px;
            height: 180px;
            border-radius: 130px;
            box-shadow: rgba(0,0,0,0.32) 0px 2px 4px;
            overflow: hidden;
            background: #ccc;
          }
        }
      }
      .researcher-info {
        margin-top: 80px;
        .researcher-info-item {
          display: flex;
          .researcher-info-avatar {
            width: 215px;
            height: 215px;
            background: #ccc;
            flex-shrink: 0;
            border-radius: 10px;
            box-shadow: rgba(0,0,0,0.32) 0px 2px 4px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .researcher-info-info {
            margin-left: 55px;
            display: flex;
            flex-direction: column;
            padding: 30px 0;
            .researcher-info-name {
              font-size: 20px;
              color: #3C4858;
            }
            .researcher-info-title {
              margin: 10px 0 20px;
              font-size: 16px;
              color: #3C4858;
            }
            .researcher-info-desc {
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
      .honor {
        margin-bottom: 80px;
        .about-title {
          margin-bottom: 30px;
        }
        &>p {
          font-size: 17px;
          color: #999;
          display: block;
          width: 820px;
          margin: 0 auto;
          overflow: hidden;
          height: 3em;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .honor-img-list {
          margin-top: 50px;
          .honor-img {
            border-radius: 10px;
            box-shadow: rgba(0,0,0,0.32) 0px 2px 4px;
            background: #ccc;
            overflow: hidden;
            width: 344px;
            height: 240px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .contact {
        width: 820px;
        margin: 0 auto;
        .about-title {
          margin-bottom: 30px;
        }
        &>p {
          font-size: 17px;
          color: #999;
          display: block;
          width: 820px;
          margin: 0 auto;
          overflow: hidden;
          height: 3em;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-align: center;
        }
        .contact-form {
          font-size: 16px;
          .form-item {
            input {
              border: none;
              border-bottom: 1px solid #e3e3e3;
              border-radius: 0;
              height: 40px;
              &:hover {
                border-color: rgb(65, 143, 215);
              }
              &:focus {
                border-color: rgb(65, 143, 215);
              }
            }
            textarea {
              border: 1px solid #e3e3e3;
              &:hover {
                border-color: rgb(65, 143, 215);
              }
              &:focus {
                border-color: rgb(65, 143, 215);
              }
            }
          }
          .form-button {
            text-align: center;
            .btn-3d8d9f {
              background-color: #3d8d9f;
              border-radius: 20px;
              width: 160px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .about-title {
      font-size: 36px;
      color: #3C4858;
      text-align: center;
      margin-bottom: 75px;
      font-weight: 800;
    }
  }
}
.paper-periodical-wapper {
  min-height: 100vh;
  background: #eaeaea;
  position: relative;
  &>nav {
    position: absolute;
  }
  .paper-periodical-main {
    background: rgb(255, 255, 255);
    filter: drop-shadow(rgba(0, 0, 0, 0.28) 0px 8px 16px);
    border-radius: 6.5px;
    transition: unset;
    margin: 0 30px;
    margin-top: -30px;
    padding: 1px 0;
    .paper-periodical-lastest {
      margin-top: 50px;
      padding: 0 30px;
      .lastest-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 35px;
        .lastest-title {
          border-left: 8px solid #dd4134;
          font-weight: 800;
          font-size: 30px;
          line-height: 20px;
          padding-left: 10px;
        }
        .search {
          width: 330px;
          position: relative;
          input {
            height: 40px;
            border-radius: 1px;
            padding-right: 32px;
            font-size: 20px;
            &:hover {
              border-color: rgb(65, 143, 215);
            }
            &:focus {
              border-color: rgb(65, 143, 215);
            }
          }
          img {
            width: 24px;
            height: 24px;
            position: absolute;
            right: 8px;
            top: 8px;
            cursor: pointer;
          }
        }
      }
      .lastest-img {
        padding: 0 15px;
        position: absolute;
        top: 0;
        width: calc(100% - 30px);
        z-index: 99;
        .tips {
          position: absolute;
          width: 50px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #bf272a;
          font-size: 14px;
          font-weight: 600;
          color: #fff;
          right: 15px;
          top: 0;
          border-bottom-left-radius: 5px;
        }
        img {
          width: 100%;
          height: 215px;
          background: #ccc;
          border-radius: 8px;
        }
      }
      .lastest-item {
        margin-top: 40px;
        height: calc(100% - 40px);
        background: rgb(255, 255, 255);
        filter: drop-shadow(rgba(0, 0, 0, 0.18) 0px 2px 4px);
        transition: unset;
        padding: 200px 15px 15px;
        display: flex;
        flex-direction: column;
        .publish-name {
          display: flex;
          justify-content: space-between;
          font-size: 18px;
          font-weight: 800;
          .publish-time {
            font-size: 14px;
            color: #c4c4c4;
            font-weight: 400;
          }
        }
        .publish-title {
          flex: 1;
          font-size: 20px;
          font-weight: 800;
          margin-top: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .publish-tags {
          display: flex;
          margin-top: 15px;
          span {
            background: #d8d6d6;
            margin-right: 8px;
            border-radius: 10px;
            width: 6em;
            text-align: center;
          }
        }
      }
      .recommend-list, .lastest-list {
        padding: 0;
        li {
          display: flex;
          margin-bottom: 50px;
          cursor: pointer;
          .recommend-img {
            width: 320px;
            height: 180px;
            border-radius: 8px;
            overflow: hidden;
            margin-right: 50px;
            flex-shrink: 0;
            box-shadow: rgba(0,0,0,0.18) 0px 2px 4px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .recommend-main {
            display: flex;
            flex-direction: column;
            padding: 5px 0;
            width: 100%;
            .recommend-title {
              font-size: 24px;
              font-weight: 800;
            }
            .recommend-desc {
              font-size: 20px;
              flex: 1;
              margin-top: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
            .recommend-from-date {
              display: flex;
              justify-content: space-between;
              margin-top: 20px;
              font-size: 16px;
              .recommend-from {
                display: flex;
                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 8px;
                }
                .lastest-tag {
                  display: inline-block;
                  padding: 1px 6px;
                  width: 6em;
                  text-align: center;
                  background: #d6d6d6;
                  margin-right: 5px;
                  border-radius: 40px;
                }
              }
              .recommend-date {
                display: flex;
                .publish-status {
                  color: rgb(205, 48, 37);
                }
                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
      .lastest-list {
        .recommend-desc {
          -webkit-line-clamp: 2 !important;
        }
      }
    }
    .more {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .line-left, .line-right {
        flex: 1;
        height: 1px;
        background: #DD4B39;
      }
      button {
        width: 120px;
        margin: 30px;
      }
    }
    .subscribe-tips {
      h3 {
        font-size: 25px;
        font-weight: 800;
        margin-bottom: 8px;
      }
      p {
        font-size: 16px;
        margin-bottom: 0;
      }
    }
    .subscribe-form {
      display: flex;
      align-items: flex-end;
      height: 100%;
      input {
        border: none;
        border-bottom: 1px solid #e3e3e3;
        border-radius: 0;
        height: 40px;
        width: 273px;
        margin: 0 10px;
        &:hover {
          border-color: rgb(65, 143, 215);
        }
        &:focus {
          border-color: rgb(65, 143, 215);
        }
      }
      textarea {
        border: 1px solid #e3e3e3;
        &:hover {
          border-color: rgb(65, 143, 215);
        }
        &:focus {
          border-color: rgb(65, 143, 215);
        }
      }
    }
  }
  .btn-3d8d9f {
    background-color: #3d8d9f;
    border-radius: 20px;
    width: 160px;
    margin: 0;
    font-size: 16px;
  }
}
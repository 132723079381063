.meeting-activity-wapper {
  min-height: 100vh;
  background: #eaeaea;
  position: relative;
  &>nav {
    position: absolute;
  }
  .meeting-activity-main {
    background: rgb(255, 255, 255);
    filter: drop-shadow(rgba(0, 0, 0, 0.28) 0px 8px 16px);
    border-radius: 6.5px;
    transition: unset;
    margin: 0 30px;
    margin-top: -30px;
    padding: 1px 0;
    .live-wapper {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      .live-big {
        width: 800px;
        height: 450px;
        background: #c4c4c4;
        border-radius: 13px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .live-small {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 380px;
        .live-small-item {
          overflow: hidden;
          position: relative;
          height: 215px;
          background: #c4c4c4;
          border-radius: 13px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .tag {
        position: absolute;
        width: 90px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #bf272a;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        left: 0;
        top: 0;
        border-bottom-right-radius: 13px;
      }
      .tips {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        height: 35px;
        background: rgba(0, 0, 0, 0.32);
        text-align: center;
        line-height: 35px;
        color: #fff;
      }
    }
    .meeting-activity-lastest {
      margin-top: 50px;
      padding: 0 30px;
      .lastest-content {
        display: flex;
        flex-wrap: wrap;
        &>div {
          position: relative;
          width: 350px; 
          height: 430px;
          cursor: pointer;  
        }
      }
      .lastest-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 35px;
        .lastest-title {
          border-left: 8px solid #dd4134;
          font-weight: 800;
          font-size: 30px;
          line-height: 20px;
          padding-left: 10px;
        }
        .search {
          width: 330px;
          position: relative;
          input {
            height: 40px;
            border-radius: 1px;
            padding-right: 32px;
            font-size: 20px;
            &:hover {
              border-color: rgb(65, 143, 215);
            }
            &:focus {
              border-color: rgb(65, 143, 215);
            }
          }
          img {
            width: 24px;
            height: 24px;
            position: absolute;
            right: 8px;
            top: 8px;
            cursor: pointer;
          }
        }
      }
      .lastest-img {
        width: 320px;
        height: 180px;
        position: relative;
        margin: 0 auto;
        z-index: 99;
        .tips {
          position: absolute;
          width: 50px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #bf272a;
          font-size: 14px;
          font-weight: 600;
          color: #fff;
          right: 0;
          top: 0;
          border-bottom-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        img {
          width: 100%;
          height: 180px;
          background: #ccc;
          border-radius: 8px;
        }
      }
      .lastest-item {
        margin-top: 40px;
        height: calc(100% - 40px);
        background: rgb(255, 255, 255);
        filter: drop-shadow(rgba(0, 0, 0, 0.18) 0px 2px 4px);
        transition: unset;
        padding: 160px 15px 25px;
        display: flex;
        flex-direction: column;
        .publish-name {
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          font-weight: 800;
          overflow: hidden;
          height: 2.8em;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .publish-time {
          font-size: 14px;
          color: #c4c4c4;
          font-weight: 400;
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
          }
        }
        .publish-title {
          flex: 1;
          font-size: 18px;
          margin: 10px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        .publish-tags {
          display: flex;
          margin-top: 10px;
          span {
            background: #d8d6d6;
            margin-right: 8px;
            border-radius: 10px;
            width: 6em;
            text-align: center;
          }
        }
      }
      .lastest-pagination {
        text-align: center;
        margin-bottom: 30px;
      }
    }
    .more {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .line-left, .line-right {
        flex: 1;
        height: 1px;
        background: #DD4B39;
      }
      button {
        width: 120px;
        margin: 30px;
      }
    }
    .more-activity {
      margin-top: 50px;
      .lastest-title {
        border-left: 8px solid #dd4134;
        font-weight: 800;
        font-size: 30px;
        line-height: 20px;
        padding-left: 10px;
        margin-bottom: 35px;
      }
      .meeting-activity-content-list {
        padding: 0;
        .meeting-activity-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .meeting-activity-item-desc {
            display: flex;
            align-items: center;
            flex: 1;
            overflow: hidden;
            margin-right: 10px;
            font-size: 18px;
            .spot {
              width: 8px;
              height: 8px;
              border-radius: 6px;
              background: #949494;
              display: block;
              margin-right: 10px;
            }
            .label {
              display: block;
              padding: 2px 10px;
              background: #1b486e;
              color: #fff;
              font-weight: 800;
              margin-right: 10px;
            }
            .meeting-label {
              background: #529CEB;
            }
            .playback-label {
              background: #2287BA;
            }
            .activity-label {
              background: #4370A1;
            }
            p {
              margin: 0;
              font-size: 18px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              flex: 1;
            }
          }
          .meeting-activity-item-time {

          }
        }
      }
    }
  }
  .btn-3d8d9f {
    background-color: #3d8d9f;
    border-radius: 20px;
    width: 160px;
    margin: 0;
    font-size: 16px;
  }
}
.footer{
    padding: 60px 0 0px;
    padding-bottom: 40px;
    position: relative;
    &>img {
        position: absolute;
        right: 30px;
        bottom: 38px;
        width: 100px;
      }

    &.footer-default{
        background-color: #f2f2f2;
    }

    nav{
        display: inline-block;
        float: left;
    }

    ul{
        margin-bottom: 0;
        padding: 0;
        list-style: none;

        li{
            display: inline-block;

            a{
                color: inherit;
                padding: $padding-base-vertical;
                font-size: $font-size-small;
                text-transform: uppercase;
                text-decoration: none;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    .copyright{
        font-size: $font-size-small;
    }

    &:after{
        display: table;
        clear: both;
        content: " ";
    }
}
